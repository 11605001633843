<template>
  <div>
    <c-table
      ref="table"
      title="선택 공정의 K-PSR 위험등록부 등재"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="450px"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      :hide-bottom="true"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-kpsr-non-riskbook',
  props: {
    selectProcessCd: {
      type: String,
      default: function() {
        return '';
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            label: '도면',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            label: '노드',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'riskTypName',
            field: 'riskTypName',
            label: '위험형태',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            label: '결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'relationProblem',
            field: 'relationProblem',
            label: '관련문제사항',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
    };
  },
  watch: {
    'selectProcessCd'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.ram.kpsr.riskReduce.list.url;
      this.getList();
    },
    getList() {
      if (!this.selectProcessCd) {
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        processCd: this.selectProcessCd,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data.riskbooks;
      },);
    },
    /* eslint-disable no-unused-vars */
  }
};
</script>
